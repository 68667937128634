import React from 'react';
import './BottomToTop.css';
import Top from '../assets/top.png';

const BottomToTop = () => {
    const [position, setPosition] = React.useState({ top: 0, left: 0 })
    React.useEffect(() => {
        window.scroll({
            top: position.top,
            left: position.left,
            behavior: 'smooth'
        })
    })

    const [visibility, setVisibility] = React.useState(false)
    const scrollTop = React.useRef()
    React.useEffect(() => {
        window.addEventListener('scroll', (e) => {
            window.scrollY > 200
                ? scrollTop.current.style.display = 'flex'
                : scrollTop.current.style.display = 'none'
        })
    })

    return (
        <>
            <span
                onClick={() => setPosition({ ...position, position: { top: 0, left: 0 } })} className="circle bottomToTopBtn btn-primary-main" ref={scrollTop}>
                <img src={Top} alt=''/>
            </span>
        </>
    )
}

export default BottomToTop
