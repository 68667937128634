import React from 'react';
import { Link } from 'react-router-dom';
import './WhyChooseUs.css';
import hightlightImg from '../assets/highlightText.png';
import aboutimg from '../assets/about-img.png';
import aboutbrand from '../assets/Grow-More- Wiserly.png';
import backgroundImage from '../assets/about-img.png';


const WhyChooseUs = ({ aboutData, columnClass }) => {

    return (
        <>
            <div className='aboutMain'>
                <div className='container'>
                    <div className="row">
                        {aboutData?.map((val) => (
                            <div key={val.id} className={`${columnClass}`} >
                                <div className='ourVision'>
                                    <div className='chooseImgBox btn-primary-main'>
                                        <img src={val?.imageUrl} alt=''/>
                                    </div>
                                    <div className='chooseInner'>
                                        <p>{val.title}</p>
                                        <span>{val.description}</span>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div >
        </>
    )
}

export default WhyChooseUs