import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ClickOnTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, [pathname]); // Trigger this effect whenever the path changes

    return null; // No UI element is rendered
};

export default ClickOnTop;
