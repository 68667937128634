import React from 'react';
import myImage from '../../assets/Frame.png';

const HeroSlider = () => {
    return (
        <div>
            <img className='heroImg' src={myImage} alt="Description of the image" />
        </div>
    )
}

export default HeroSlider