import React, { useState, useEffect } from "react";
import hightlightImg from "../assets/highlightText.png";
import "../lifeOurs/lifeours.css";
import WaveEffect from "../innerComponents/WaveEffect";
import { fetchGrowMoreLifeData } from "../../services/ApiHelper";
import PerksBenefits from "../innerComponents/PerksBenefits";
import CenteredLoader from "../appLoader/CenteredLoader";
const Lifeours = () => {
  const [growmoreData, setGrowMoreData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      setShowLoader(true);
      const growmoreData = await fetchGrowMoreLifeData();
      setShowLoader(false);
      setGrowMoreData(growmoreData?.data);
    } catch (err) {
      setShowLoader(false);
      console.log(err.message);
    }
  };
  const renderContent = () => {
    const lifeTitle = growmoreData?.lifeAtCompany?.description
      ?.split("\n")
      ?.slice(1)
      ?.filter((line) => line.trim() !== "");
    return lifeTitle?.map((line, index) => <p key={index}>{line}</p>);
  };
  return (
    <div className="lifeoursContent">
      <div className="container-fluid">
        <WaveEffect growmoreData={growmoreData} />
      </div>
      <div className="container">
        <div class="text-center my-5 lifeTitle">
          <h1>
            <span>
              Why join our team
              <img src={hightlightImg} alt="Description of the image" />
            </span>
          </h1>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="team_image">
              <img src={growmoreData?.lifeAtCompany?.imageUrl} alt="" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="chooseUsDesc">
              <p>{renderContent()}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div class="blog_banner text-center my-5">
          <h1>
            <span>
              Perks & Benefits
              <img src={hightlightImg} alt="Description of the image" />
            </span>
          </h1>
        </div>
        <PerksBenefits
          growmoreData={growmoreData?.perksAndBenefits}
          columnClass="col-lg-4 col-md-6 col-sm-12"
        />
      </div>
      {showLoader && <CenteredLoader />}
    </div>
  );
};
export default Lifeours;
