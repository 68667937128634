import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";

const ServiceDetail = () => {
  const { val } = useParams();
  const location = useLocation();
  const [service, setService] = useState(location.state?.val || null);
  console.log("this is service", service);

  return (
    <div className="serviceDetails container">
      <h1>{service?.name}</h1>
      <p>{service?.description}</p>
    </div>
  );
};
export default ServiceDetail;
