import React, { useState, useEffect } from "react";
import "../privacyPolicy/Privacypolicy.css";
import { fetchPolicyData } from "../../services/ApiHelper";
import CenteredLoader from "../appLoader/CenteredLoader";
const Privacypolicy = () => {
  const [policyData, setPolicyData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        setShowLoader(true);
        const result = await fetchPolicyData();
        setShowLoader(false);
        setPolicyData(result.data);
      } catch (err) {
        setShowLoader(false);
        console.log(err.message);
      }
    };
    getData();
  }, []);
  useEffect(() => {
    renderContent();
  }, [policyData]);
  const renderContent = () => {
    return policyData?.privacyPolicyValues?.desc
      ?.split("\n")
      .map((item, index) => <p key={index}>{item}</p>);
  };
  return (
    <div className="privacyContent my-5">
      {showLoader ? (
        <CenteredLoader />
      ) : (
        <div className="container">
          <h4>Privacy Policy Page</h4>
          <p>{renderContent()}</p>
        </div>
      )}
    </div>
  );
};
export default Privacypolicy;
