import React from 'react';
import imgsrc from '../assets/Group 33915.png';
import '../achivement/Achivement.css';

const Achivement = ({ achieveData }) => {
    return (
        <>
            <div class='achiveMain mt-5'>
                <div class='container'>
                    <div class="row">
                        {achieveData?.promotionalDetail?.map((val) => (
                            <div key={val.id} className="col-lg-3 col-md-6 col-sm-12">
                                <div className="achiveBox">
                                    <p>{val.count}</p>
                                    <span>{val.title}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div class='achiveImg'>
                <img src={imgsrc} alt='' />
            </div>
        </>
    )
}

export default Achivement