import React from 'react';
import './WaveEffect.css';

const WaveEffect = ({ growmoreData }) => {
    const renderContent = () => {
        const lifeTitle = growmoreData?.lifeAtCompany?.description?.split('\n')[0]
        return <p>{lifeTitle}</p>
    };
    return (
        <div className="header p-0">
            <div className="inner-header flex">
                <div className='container'>
                    <h1>Life@ GrowMore Wiserly</h1>
                    <p>
                        {renderContent()}
                    </p>
                </div>
            </div>

            <div>
                <svg
                    className="waves"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none"
                    shapeRendering="auto"
                >
                    <defs>
                        <path
                            id="gentle-wave"
                            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                        />
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>
        </div>
    )
}

export default WaveEffect