import React from "react";
import { Hourglass } from "react-loader-spinner";
import "./CenteredLoader.css"; // Import the CSS file for styling

const CenteredLoader = () => {
  return (
    <div className="loader-container">
      <Hourglass
        visible={true}
        height="40"
        width="40"
        ariaLabel="hourglass-loading"
        wrapperClass="hourglass-wrapper"
        colors={["#3df2e6", "#33ddff"]}
      />
      <p className="loader-heading">Crafting the Future</p>
    </div>
  );
};

export default CenteredLoader;
