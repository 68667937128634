import React, { useRef, useEffect } from "react";
//import ReactGA from 'react-ga4';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import About from "./components/about/About";
import Home from "./components/home/Home";
import Services from "./components/services/Services";
import Projects from "./components/projects/Projects";
import Technology from "./components/technology/Technology";
import Footer from "./components/footer/Footer";
import BottomToTop from "./components/bottomToTop/BottomToTop";
import Privacypolicy from "./components/privacyPolicy/Privacypolicy";
import Lifeours from "./components/lifeOurs/lifeours";
import ChatBot from "./components/chatbot/Chatbot";
import CircleCursor from "./components/circleCursor/CircleCursor";
import Jobs from "./components/jobs/Jobs";
import ClickOnTop from "./components/innerComponents/ClickOnTop";
import Blogs from "./components/blogs/Blogs";
import ServiceDetail from "./components/services/ServiceDetail";
import ReactGA from "react-ga4";

const App = () => {
  const scrollToBottomRef = useRef(null);
  const TRACKING_ID = "G-7CXPTE92E2";
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  return (
    <Router>
      <Navbar onScrollToBottom={() => scrollToBottomRef.current()} />
      <ClickOnTop />
      <Routes>
        <Route
          exact
          path="/"
          element={<Home setScrollToBottom={scrollToBottomRef} />}
        />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/service" element={<Services />} />
        <Route path="/service/:title" element={<ServiceDetail />} />
        <Route exact path="/project" element={<Projects />} />
        <Route exact path="/technology" element={<Technology />} />
        <Route exact path="/Privacypolicy" element={<Privacypolicy />} />
        <Route exact path="/lifeours" element={<Lifeours />} />
        <Route exact path="/jobs" element={<Jobs />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
      <BottomToTop />
      <CircleCursor />
      {/* <ChatBot /> */}
    </Router>
  );
};

export default App;
