import React from 'react';
import hightlightImg from '../assets/highlightText.png';
import ClientReviewSlider from './innerComponents/ClientReviewSlider';
import "./ClientReviews.css";

const ClientReviews = ({googleReview}) => {
    return (
        <div className='reviewsMain'>
            <div className='container'>
                <div className="review_banner text-center" style={{ paddingTop: '20px' }}>
                    <h1>
                        <span>
                            What People Think About Us
                            <img src={hightlightImg} alt="Highlight" />
                        </span>
                    </h1>
                </div>
                <ClientReviewSlider googleReview={googleReview} />
            </div>
        </div>
    )
}
export default ClientReviews