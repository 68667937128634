import React, { useState } from "react";
import "../connect/Connect.css";
import hightlightImg from "../assets/highlightText.png";
import backgroundImage from "../assets/blog-bg.png";
import { submitContactData } from "../../services/ApiHelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure toastify styles are imported

function Connect({ ConnectRef }) {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    contact: "",
    textarea: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const dataToSubmit = {
        name: formData.username,
        email: formData.email,
        contact: formData.contact,
        message: formData.textarea,
      };
      const result = await submitContactData(dataToSubmit);
      if (result?.message) {
        toast.success(result?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: { fontSize: 12 },
        });
      } else {
        toast.error("Something went wrong,Please try later", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          type: "error",
          style: { fontSize: 12 },
        });
      }
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.username.trim()) {
      errors.username = "Username is required";
    } else if (data.username.length < 4) {
      errors.username = "Username must be at least 4 characters long";
    }

    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }

    if (!data.contact) {
      errors.contact = "Contact number is required";
    } else if (data.contact.length < 10) {
      errors.contact = "Contact must be at least 10 characters long";
    }

    if (!data.textarea) {
      errors.textarea = "Message is required";
    } else if (data.textarea.length < 15) {
      errors.textarea = "Message must be at least 15 characters long";
    }
    return errors;
  };

  return (
    <div
      ref={ConnectRef}
      className="connectMain"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="about_banner text-center mt-3 mb-5">
              <h1>
                <span>
                  Get In Touch With US
                  <img src={hightlightImg} alt="Description of the image" />
                </span>
              </h1>
            </div>
            <div className="row">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d889.5230393036552!2d75.79394026963493!3d26.900570194528928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDU0JzAyLjEiTiA3NcKwNDcnNDAuNSJF!5e0!3m2!1sen!2sin!4v1734600892043!5m2!1sen!2sin"
                width="600"
                height="500"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="about_banner text-center mt-3 mb-5">
              <h1>
                <span>
                  Need Some Help
                  <img src={hightlightImg} alt="Description of the image" />
                </span>
              </h1>
            </div>
            <div className="form-container">
              <div className="formHeader">
                <h3 className="text-center mb-4">Let’s Connect</h3>
              </div>
              <form onSubmit={handleSubmit}>
                <div>
                  <label className="form-label">Name :</label>
                  <input
                    className="form-input"
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                  />
                  {errors.username && (
                    <span className="error-message">{errors.username}</span>
                  )}
                </div>
                <div>
                  <label className="form-label">Email :</label>
                  <input
                    className="form-input"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span className="error-message">{errors.email}</span>
                  )}
                </div>
                <div>
                  <label className="form-label">Contact Number :</label>
                  <input
                    className="form-input"
                    type="text"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChange}
                  />
                  {errors.contact && (
                    <span className="error-message">{errors.contact}</span>
                  )}
                </div>
                <div>
                  <label className="form-label">Message :</label>
                  <textarea
                    className="form-input"
                    name="textarea"
                    value={formData.textarea}
                    onChange={handleChange}
                  />
                  {errors.textarea && (
                    <span className="error-message">{errors.textarea}</span>
                  )}
                </div>
                <button
                  className="submit-button btn-primary-main mt-2"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Ensure this is added */}
    </div>
  );
}

export default Connect;
