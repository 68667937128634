import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../technology/Technology.css";
import backgroundImage from "../assets/tech_bg.png";
import hightlightImg from "../assets/highlightText.png";
import nextImg from "../assets/arrow-right.png";
import { fetchTechnologiesData } from "../../services/ApiHelper";
import CenteredLoader from "../appLoader/CenteredLoader";

const Technology = ({ screenType }) => {
  const [techData, setTechData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      setShowLoader(true);
      const techData = await fetchTechnologiesData();
      setShowLoader(false);
      setTechData(techData?.data);
    } catch (err) {
      setShowLoader(false);
      console.log(err.message);
    }
  };
  return (
    <>
      {screenType == "Home" ? (
        <div class="technologyMain">
          <div class="container">
            <div class="technology_banner text-center mb-2">
              <h1>
                <span>
                  Technology
                  <img src={hightlightImg} alt="Description of the image" />
                </span>
                We Use
              </h1>
            </div>
            <div class="row">
              {techData?.technolgies?.slice(0, 6)?.map((val) => (
                <div key={val.id} className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="technologyBox mt-4 p-3"
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                  >
                    <img
                      class="btn-primary-main swing"
                      src={val.imageUrl}
                      alt=""
                    />
                    <div class="technologyBoxInner">
                      <p>{val.title}</p>
                      <span class="clamped-text">
                        {val.description.slice(0, 100)}...
                      </span>
                      <Link to="/technology" class="ms-auto d-flex">
                        <img
                          className="seeMoreIcon me-3"
                          src={nextImg}
                          alt="Service Icon"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
              <Link
                to="/technology"
                className="btn btn-primary-main me-auto px-4"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  width: "auto",
                  margin: "20px auto 0",
                }}
              >
                See More
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          {showLoader ? (
            <CenteredLoader />
          ) : (
            <div class="technologyMain py-5">
              <div class="container">
                <div class="technology_banner text-center mb-2">
                  <h1>
                    <span>
                      Technology
                      <img src={hightlightImg} alt="Description of the image" />
                    </span>
                    We Used
                  </h1>
                </div>
                <div class="row">
                  {techData?.technolgies?.map((val) => (
                    <div key={val.id} className="col-lg-6 col-md-6 col-sm-12">
                      <div
                        className="technologyBox technologyBoxMain mt-5 p-3"
                        style={{ backgroundImage: `url(${backgroundImage})` }}
                      >
                        <img
                          class="btn-primary-main swing"
                          src={val.imageUrl}
                          alt=""
                        />
                        <div class="technologyBoxInner">
                          <p>{val.title}</p>
                          <span>{val.description}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Technology;
