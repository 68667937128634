import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../about/about.css";
import hightlightImg from "../assets/highlightText.png";
import aboutimg from "../assets/about-img.png";
import aboutbrand from "../assets/Grow-More- Wiserly.png";
import backgroundImage from "../assets/about-img.png";
import WhyChooseUs from "../innerComponents/WhyChooseUs";
import { fetchAboutData } from "../../services/ApiHelper";
import CenteredLoader from "../appLoader/CenteredLoader";

const About = ({ screentype }) => {
  const [aboutData, setAboutData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      setShowLoader(true);
      const aboutdata = await fetchAboutData();
      setShowLoader(false);
      setAboutData(aboutdata?.data);
    } catch (error) {
      setShowLoader(false);
    }
  };

  return (
    <>
      {screentype == "Home" ? (
        <div className="aboutMain mt-3 mb-5">
          <div className="container">
            <div className="about_banner text-center">
              <h1>
                <span>
                  About Us
                  <img src={hightlightImg} alt="Description of the image" />
                </span>
              </h1>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="aboutBoxMain mt-4">
                  <img src={aboutimg} style={{ width: "100%" }} alt="" />
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="mt-4">
                  <p className="lineHeight">
                    {aboutData?.aboutUsValues?.description}
                  </p>
                  <Link
                    to="/about"
                    className="btn btn-primary-main me-auto px-4"
                    style={{ textDecoration: "none" }}
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutSepBrand">
            <img src={aboutbrand} alt="" />
          </div>
        </div>
      ) : (
        <>
          <div className="aboutMain">
            <div className="container">
              <div className="about_banner text-center">
                <h1>
                  <span>About Us</span>
                </h1>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div
                    className="aboutBox mt-5"
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                  ></div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="mt-3">
                    <p className="lineHeight">
                      {aboutData?.aboutUsValues?.description}
                    </p>
                  </div>
                </div>
                <div className="about_banner text-center mt-2 mb-2">
                  <h1>
                    <span>
                      Our Vision
                      <img src={hightlightImg} alt="Description of the image" />
                    </span>
                  </h1>
                </div>
                {aboutData?.missionVisionValues?.map((val) => (
                  <div key={val.id} className="col-lg-4 col-md-6 col-sm-12">
                    <div className="visionBlock mt-4">
                      <img
                        src={val.imageUrl}
                        className="btn-primary-main"
                        alt=""
                      />
                      <div className="visionTitle">
                        <p>
                          {val.title[0]}
                          <span> {val.title[1]}</span>
                        </p>
                      </div>
                      <div className="visionDesc">
                        <span>{val.description}</span>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="about_banner text-center mt-2 mb-2">
                  <h1>
                    <span>
                      Why Choose Us
                      <img src={hightlightImg} alt="Description of the image" />
                    </span>
                  </h1>
                </div>
                <WhyChooseUs
                  aboutData={aboutData?.whyChooseValues}
                  columnClass="col-lg-3 col-md-6 col-sm-12"
                />
              </div>
            </div>
          </div>
          {showLoader && <CenteredLoader />}
        </>
      )}
    </>
  );
};
export default About;
