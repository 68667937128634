import React from 'react';
import './PerksBenefits.css';


const PerksBenefits = ({ growmoreData, columnClass }) => {

    return (
        <>
            <div className='aboutMain'>
                <div className='container'>
                    <div className="row">
                        {growmoreData?.map((val) => (
                            <div key={val.id} className={`${columnClass}`} >
                                <div className='ourVision'>
                                    <div className='chooseImgBox btn-primary-main'>
                                        <img src={val?.imageUrl} alt='' />
                                    </div>
                                    <div className='chooseInner'>
                                        <p>{val?.title}</p>
                                        <span>{val?.description}</span>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div >
        </>
    )
}

export default PerksBenefits;