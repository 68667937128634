import React, { useRef, useState, useEffect } from 'react';
import '../navbar/Navbar.css';
import { Link, useLocation } from "react-router-dom";
import logo from '../assets/mainLogo.png';

const Navbar = ({ onScrollToBottom }) => {
    const ConnectRef = useRef(null);
    const navbarCollapseRef = useRef(null);
    const location = useLocation();
    const handleToggle = () => {
        if (navbarCollapseRef.current && navbarCollapseRef.current.classList.contains('show')) {
            navbarCollapseRef.current.classList.remove('show');
        }
    };
    const scrollToConnect = () => {
        alert("call")
    }
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <div
            className={`menuHeader ${isActive ? "active" : ""}`}>
            <nav className="container navbar navbar-expand-lg">
                <div className="container-fluid">
                    <div className='mobileBox d-flex align-items-center'>
                        <Link className="navbar-brand d-flex" to="/" style={{ margin: '0' }}>
                            <img src={logo} alt="Logo" />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                        ref={navbarCollapseRef}
                    >
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item ms-3">
                                <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} aria-current="page" to="/" onClick={handleToggle}>
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item ms-3">
                                <Link className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`} to="/about" onClick={handleToggle}>
                                    About
                                </Link>
                            </li>
                            <li className="nav-item ms-3">
                                <Link className={`nav-link ${location.pathname === '/project' ? 'active' : ''}`} to="/project" onClick={handleToggle}>
                                    Case Studies
                                </Link>
                            </li>
                            <li className="nav-item ms-3">
                                <Link className={`nav-link ${location.pathname === '/service' ? 'active' : ''}`} to="/service" onClick={handleToggle}>
                                    Services
                                </Link>
                            </li>
                            <li className="nav-item ms-3">
                                <Link className={`nav-link ${location.pathname === '/blogs' ? 'active' : ''}`} to="/blogs" onClick={handleToggle}>
                                    Blogs
                                </Link>
                            </li>
                            <li className="nav-item ms-3">
                                <button
                                    type="button"
                                    className="btn btn-primary-main"
                                    onClick={() => {
                                        handleToggle();
                                        onScrollToBottom();
                                    }}
                                >
                                    Let's Talk
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar