import React, { useState, useEffect } from "react";
import "./ClientReviewSlider.css";
import googleImg from "../../assets/google.png";
const ClientReviewSlider = ({ googleReview}) => {
    const [sliderConf, setSliderConf] = useState({
        sliderPointer: 0,
        data: [],
    });
    const [isPaused, setIsPaused] = useState(false);
    useEffect(() => {
        if (googleReview?.length > 0) {
            setSliderConf((prev) => ({
                ...prev,
                data: googleReview
            }));
        }
    }, [googleReview]);
    useEffect(() => {
        if (!isPaused && sliderConf.data.length > 0) {
            const interval = setInterval(() => {
                setSliderConf((prev) => {
                    const { sliderPointer, data } = prev;
                    const nextPointer =
                        sliderPointer === data.length - 1 ? 0 : sliderPointer + 1;
                    return { ...prev, sliderPointer: nextPointer };
                });
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [isPaused, sliderConf.data]);
    const goToSlide = (index) => {
        setSliderConf((prev) => ({ ...prev, sliderPointer: index }));
    };
    const { sliderPointer, data } = sliderConf;
    const currentSlide = data.length > 0 ? data[sliderPointer] : null;
    const renderStars = (rating) => {
        const stars = Array(5)
            .fill(0)
            .map((_, index) => (
                <span key={index} className={index < rating ? "star filled" : "star"}>
                    ★
                </span>
            ));
        return <div className="star-rating">{stars}</div>;
    };
    return (
        <div
            className="SliderMain"
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
        >
            {currentSlide ? (
                <div className="slideshow-container">
                    <div className="mySlides">
                        <q>{currentSlide.description}</q>
                        <p className="author">- {currentSlide.name}</p>
                        <div>
                            <img src={googleImg} alt="google-image" />
                        </div>
                        {renderStars(currentSlide.ratingCount)}
                    </div>
                </div>
            ) : (
                <p>Loading reviews...</p>
            )}
            <div className="dot-container">
                {data.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${sliderPointer === index ? "active" : ""}`}
                        onClick={() => goToSlide(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};
export default ClientReviewSlider;