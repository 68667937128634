import React, { useRef, useEffect, useState } from "react";
import "../home/Home.css";
import anime from "animejs";
import Services from "../services/Services";
import Technology from "../technology/Technology";
import Projects from "../projects/Projects";
import Achivement from "../achivement/Achivement";
import Testimonial from "../testimonials/Testimonial";
import About from "../about/About";
import Blogs from "../blogs/Blogs";
import WeWorkWith from "../weWorkWith/WeWorkWith";
import Connect from "../connect/Connect";
import HeroSlider from "./innerComponents/HeroSlider";
import WaveAnimation from "./innerComponents/WaveAnimation";
import { fetchHomeData } from "../../services/ApiHelper";
import ClientReviews from "../clientReviews/ClientReviews";
import CenteredLoader from "../appLoader/CenteredLoader";

const Home = ({ setScrollToBottom }) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
  const [homeData, setHomeData] = useState(null);
  const [showRating, setShowRating] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const ConnectRef = useRef(null);

  const scrollToConnect = () => {
    ConnectRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (homeData?.ratingReviews?.length > 0) {
      setShowRating(true);
    }
  }, [homeData]);
  const getData = async () => {
    try {
      setShowLoader(true);
      const homeData = await fetchHomeData();
      setHomeData(homeData?.data);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      console.log(err.message);
    }
  };
  useEffect(() => {
    if (setScrollToBottom) {
      setScrollToBottom.current = scrollToBottom;
    }
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 992);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setScrollToBottom]);
  return (
    <>
      <div className="dynamic-background">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 ">
              <div className="bannerLeft">
                <h1>Driving Success through Innovative Solutions</h1>
                <p className={isLargeScreen ? "ml3" : ""}>
                  With our expertise in app development, UI/UX design, and
                  strategic marketing, we help drive your business towards
                  unprecedented success
                </p>
                <button
                  type="button"
                  class="btn btn-primary-main me-auto px-4"
                  onClick={scrollToConnect}
                >
                  Let's Talk
                </button>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="text-center heroAnimations">
                <HeroSlider />
              </div>
            </div>
          </div>
        </div>
        <div className="waveAnimation">
          <WaveAnimation />
        </div>
      </div>
      <Services screenType="Home" />
      <section className="parallex py-3">
        <Technology screenType="Home" />
      </section>
      <Achivement achieveData={homeData} />
      <Testimonial testimonial={homeData} />
      <About screentype="Home" />
      <section className="parallex2 pb-3">
        <WeWorkWith workWithData={homeData} />
      </section>

      {showRating ? (
        <section style={{ backgroundColor: "#1c1c1c" }}>
          <ClientReviews googleReview={homeData?.ratingReviews} />
        </section>
      ) : null}
      <Blogs />
      <Connect ConnectRef={ConnectRef} />
      {showLoader && <CenteredLoader />}
    </>
  );
};
export default Home;
