import React from "react";
import footerLogo from "../assets/footer-logo.png";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import medium from "../assets/medium.png";
import linkedin from "../assets/linkedin.png";
import "../footer/Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  const footerData = [
    {
      id: 1,
      address: "Jaipur, Rajasthan(302021)",
      phoneNo: "+91 9414283688, +91 8949859282",
      mailConnect: "info@growmorewiserly.com",
    },
  ];
  return (
    <div class="footerMain">
      <div class="container-fluid">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="footerlogoblock">
                <img src={footerLogo} alt="" />
                <span>
                  GMW (GrowMore Wiserly) is an IT-based software company
                  specializing in mobile app development, UX/UI design, web
                  development, testing, and IT consultation.
                </span>
                <ul>
                  <li>
                    <Link
                      to="https://www.facebook.com/profile.php?id=61550348587519"
                      target="_blank"
                    >
                      <img src={facebook} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/growmorewiserly"
                      target="_blank"
                    >
                      <img src={instagram} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/growmore-wiserly/"
                      target="_blank"
                    >
                      <img src={linkedin} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div className="policy">
                <h5 style={{ color: "#fff", marginBottom: "30px" }}>
                  Important Links
                </h5>
                <Link to="/Privacypolicy">Privacy Policy</Link>
                <Link to="/lifeours">Life@GrowMoreWiserly</Link>
                <Link to="/jobs">Jobs</Link>
                <Link to="/technology">Technologies</Link>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div className="footerContectDetail">
                <h5 style={{ color: "#fff", marginBottom: "30px" }}>
                  Contact Details
                </h5>
                <ul>
                  {footerData?.map((val) => (
                    <li key={val.id}>
                      <p>
                        Address : <span>{val.address}</span>
                      </p>
                      <p>
                        Phone No : <span>{val.phoneNo}</span>
                      </p>
                      <p>
                        Email :{" "}
                        <a
                          href={`mailto:${val.mailConnect}`}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            display: "inline",
                            margin: "0",
                          }}
                        >
                          <span>{val.mailConnect}</span>
                        </a>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div>
            <ul className="terms-cond">
              <li>
                <div class="copyrightblock" style={{ marginTop: "20px" }}>
                  <span>© 2025 GMW. All rights reserved.</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
