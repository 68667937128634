import React from 'react';
import { Link } from "react-router-dom";
import '../weWorkWith/WeWorkWith.css';
import hightlightImg from '../assets/highlightText.png';

const WeWorkWith = ({ workWithData, screenType }) => {
    return (
        <div class='workwithMain mt-5'>
            <div class='container'>
                <div class='workwith_banner text-center mb-2 pt-3'>
                    <h1>Industries We
                        <span>
                            Work
                            <img src={hightlightImg} alt="Description of the image" />
                        </span>
                        With</h1>
                </div>

                <div class="row">
                    {workWithData?.industriesWeServed?.map((val) => (
                        <div key={val.id} className="col-lg-3 col-md-6 col-sm-6 mt-3">
                            <div className="weWorkBox weworkwith text-center p-3">
                                <img class='btn-primary-main swing' src={val.imageUrl} alt='' />
                                <div class='workWithInner mt-2'>
                                    <p class='mb-0'>{val.title}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WeWorkWith