import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import "../services/Services.css";
import hightlightImg from "../assets/highlightText.png";
import nextImg from "../assets/arrow-right.png";
import { fetchServiceData } from "../../services/ApiHelper";
import CenteredLoader from "../appLoader/CenteredLoader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Services = ({ val, screenType }) => {
  const [serviceData, setServiceData] = useState(null);
  const [value, setValue] = React.useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setShowLoader(true);
      const serviceData = await fetchServiceData();

      setServiceData(serviceData?.data);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      console.log(err.message);
    }
  };

  const formatServiceName = (name) => {
    return name?.toLowerCase().replace(/ /g, "-");
  };

  return (
    <div class="serviceMain mt-4 mb-3">
      <div class="container">
        <div class="service_banner text-center mb-4">
          <h1>
            <span>
              Services
              <img src={hightlightImg} alt="Description of the image" />
            </span>
            We Are Offering
          </h1>
        </div>
        {screenType == "Home" ? (
          <div onClick={() => navigate("/service")} class="row">
            {serviceData?.services?.slice(0, 6)?.map((val) => (
              <div key={val.id} className="col-lg-4 col-md-6 col-sm-12 ">
                <div className="serviceBox mt-3 p-3">
                  <img
                    className="btn-primary-main swing"
                    src={val.imageUrl}
                    alt={val.title || "Service Image"}
                  />
                  <div class="serviceBoxInner mt-5">
                    <p>{val.title}</p>
                    <span class="clamped-text">
                      {val.description.slice(0, 120)}...
                    </span>
                    <Link
                      // to={`/service/${formatServiceName(val?.title)}`}
                      // state={{ val }}
                      class="ms-auto"
                    >
                      <img
                        className="seeMoreIcon"
                        src={nextImg}
                        alt="Service Icon"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
            <Link
              to="/service"
              className="btn btn-primary-main me-auto px-4"
              style={{
                textDecoration: "none",
                display: "flex",
                width: "auto",
                margin: "20px auto 0",
              }}
            >
              See More
            </Link>
          </div>
        ) : (
          <div class="row">
            {serviceData?.services?.map((val) => (
              <Link
                // to={`/service/${formatServiceName(val?.title)}`}
                // state={{ val }}
                // key={val.id}
                className="col-lg-12 col-md-6 col-sm-12 mt-3"
                style={{ textDecoration: "none", color: "inherit" }} // Add this style
              >
                <div className="serviceBox mt-3 p-3">
                  <img
                    className="btn-primary-main swing"
                    src={val.imageUrl}
                    alt={val.title || "Service Image"}
                  />
                  <div className="serviceBoxInner mt-5">
                    <p>{val.title}</p>
                    <span>{val.description}</span>
                    <Link
                      // to={`/service/${formatServiceName(val?.title)}`}
                      // state={{ val }}
                      class="ms-auto"
                    >
                      <img
                        className="seeMoreIcon"
                        src={nextImg}
                        alt="Service Icon"
                      />
                    </Link>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
      {showLoader && <CenteredLoader />}
    </div>
  );
};
export default Services;
