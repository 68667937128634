import React, { useState } from 'react';
import '../blogs/Blogs.css';
import hightlightImg from '../assets/highlightText.png';

const Blogs = ({ screentype }) => {
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const blogData = [        
        {
            id: 1,
            title: 'React Native’s New Architecture Explore and Features',
            description: `
            In the fast moving world, it’s important to upgrade everything in your lives. In the rising lifestyle, technology has also been upgraded rapidly. The cross-platform mobile applications went through drastic change in 2024–2025. Isn’t it fascinating how cross-platform mobile applications in 2024 are easy-in the lives of developers’ community? React Native is one such technology which has turn-the tables affirmatively in the tech-world. Let us find out how its new architecture has been upgraded and what all are its benefits?
            `,
            sections: [
                {
                    heading: 'Key Features of React Native’s New Architecture in 2024:',
                    points: [
                        {
                            title: 'JavaScript Interface (JSI)',
                            description: 'JSI has a vital role in any app platform, it now replaces the intermediation and directly interacts with the code without hassle. It is proven to be an efficient functionality of native layers. Enhances the performance and supports multi-threading.'
                        },
                        {
                            title: 'TurboModules',
                            description: 'When it comes to integration of native modules and JavaScript, TurboModules is here. Say bye bye to the old loaded upfront and say hello to faster start time. Reduce the extra burden of native functionalities.'
                        },
                        {
                            title: 'Fabric Renderer',
                            description: 'Replacing the footprints of UI manager, Fabric Renderer is new talk of the town of rendering systems. It provides a smoother interface to the users and gives better support to animations. By reducing the latency, it has been a win-win situation for developers.'
                        },
                        {
                            title: 'Hermes Engine Integration',
                            description: 'An optimized JS Engine for React Native is new Hermes! It reduces memory consumption and makes it easy to install with less memory space. It also offers faster app initialization.'
                        }
                    ]
                },
                {
                    heading: 'How does React Native new architecture benefit in 2024?',
                    points: [
                        { title: 'Reduced memory consumption', description: '' },
                        { title: 'Faster app updates', description: '' },
                        { title: 'Smooth and user-friendly UI', description: '' },
                        { title: 'Easy to optimize', description: '' },
                        { title: 'Quick communication between Native Layers and JavaScript', description: '' },
                        { title: 'Supports multi-threading', description: '' },
                        { title: 'Provide efficiency', description: '' },
                        { title: 'Improves animation', description: '' }
                    ]
                },
                {
                    heading: 'Nutshell:',
                    description: `
                    If you are looking for a golden deal to create a cross-platform app for business in 2024, then make your business website ready using React Native technology. Its new and modern architecture provides a user-friendly and highly interactive website which will surely raise the footfalls on your website. Raise the success graphs by implementing the new React Native now!
                    `
                }
            ],
            blogImg: require('../assets/blog-post1.png'),
            nextimg: require('../assets/arrow-right.png')
        },        
        {
            id: 2,
            title: 'Android OS 15: Enhancements and Features',
            description: `
            Android OS has consistently led the mobile operating system market, with over 2.5 billion active devices worldwide. The recent release of Android OS 15 marks another leap forward, bringing significant enhancements that cater to user needs and security. This update not only reflects the evolving mobile landscape but also emphasizes Google’s commitment to providing a safer and more enjoyable user experience. This article explores the standout features that make Android OS 15 compelling, from advanced security to an improved user interface.
            `,
            sections: [
                {
                    heading: 'Enhanced Privacy and Security Features',
                    points: [
                        {
                            title: 'Advanced Privacy Dashboard',
                            description: 'Android OS 15 introduces a revamped Privacy Dashboard that gives users a clearer view of their data usage. Users can now see which apps access sensitive information, such as location and contacts, and make more informed choices. In light of recent studies showing a 20% increase in privacy breaches, these updates are vital for safeguarding personal data.'
                        },
                        {
                            title: 'Improved App Permissions',
                            description: 'With Android OS 15, managing app permissions is more user-friendly. Now, when an app requests access to certain features, users have an option to allow temporary access. For instance, a popular social media app can now be granted location access only while in use, reducing the risk of constant tracking.'
                        },
                        {
                            title: 'Biometric Authentication Enhancements',
                            description: 'The update also strengthens biometric security. Facial recognition and fingerprint sensors are now more reliable, reducing false rejections and enhancing user confidence when accessing sensitive apps.'
                        }
                    ]
                },
                {
                    heading: 'Redesigned User Interface and Experience',
                    points: [
                        {
                            title: 'Visual Refresh',
                            description: 'Android OS 15 brings a fresh visual design, featuring new widgets and fonts that enhance the aesthetic appeal. The updated color palette offers a more vibrant and engaging look, making the overall user experience more visually pleasing.'
                        },
                        {
                            title: 'Improved Navigation',
                            description: 'User navigation has seen significant improvements. The updated interface allows for easier access to apps and settings, streamlining processes compared to previous versions. Users will find it simpler to move between apps and manage multitasking without confusion.'
                        },
                        {
                            title: 'Customization Options',
                            description: 'Expanded customization options let users make their devices truly unique. Android OS 15 supports personalized widgets, themes, and layouts. Users can express their style while enjoying functionality that suits their preferences.'
                        }
                    ]
                },
                {
                    heading: 'Enhanced Performance and Efficiency',
                    points: [
                        {
                            title: 'Optimized Resource Management',
                            description: 'Android OS 15 optimizes battery life and storage, ensuring apps run smoothly even on devices with limited resources. The new resource management system tackles background processes effectively, allowing users to enjoy longer usage times without interruptions.'
                        },
                        {
                            title: 'Improved RAM Management',
                            description: 'This version enhances RAM management, allowing for seamless multitasking. Users can switch between apps without lag, creating a smooth and efficient workflow that enhances productivity.'
                        },
                        {
                            title: 'Game Mode Enhancements',
                            description: 'For gaming enthusiasts, Android OS 15 offers improved performance and dedicated features for a better gaming experience. The update reduces latency and enhances frame rates, making gameplay more enjoyable.'
                        }
                    ]
                },
                {
                    heading: 'Innovative AI-Powered Features',
                    points: [
                        {
                            title: 'AI-Powered Assistant Enhancements',
                            description: 'Improvements to the voice assistant in Android OS 15 make it faster and smarter. The AI can now understand commands with greater accuracy, saving time while interacting with the device.'
                        },
                        {
                            title: 'Smart Suggestions and Predictions',
                            description: 'OS 15 uses AI to deliver more relevant suggestions based on user behavior. For example, it can recommend apps or settings at the right moment, preventing users from wasting time searching for what they need.'
                        },
                        {
                            title: 'AI-Driven Photography Enhancements',
                            description: 'Photography features have received an AI boost as well. The AI now offers intelligent editing options and scene optimization. Professional photographers have praised the update for its ability to enhance images automatically, producing stunning results in seconds.'
                        }
                    ]
                },
                {
                    heading: 'Cross-Device Integration and Connectivity',
                    points: [
                        {
                            title: 'Seamless Multi-Device Experience',
                            description: 'Android OS 15 focuses on connectivity across devices, allowing for a more integrated experience. Users can share information and settings seamlessly between smartphones, tablets, and even smart TVs.'
                        },
                        {
                            title: 'Enhanced File Sharing',
                            description: 'Transferring files has never been easier. The update improves the speed and simplicity of file sharing between Android devices, making collaboration more efficient.'
                        },
                        {
                            title: 'Improved Connectivity Options',
                            description: 'With advancements in Wi-Fi and Bluetooth connectivity, Android OS 15 ensures stable and fast connections. These improvements enhance streaming and data sharing, contributing to a more enjoyable user experience.'
                        }
                    ]
                },
                {
                    heading: 'Conclusion',
                    description: `
                    Android OS 15 offers an array of exciting features that enhance privacy, security, performance, and overall user experience. The advanced privacy dashboard, redesigned interface, and improved RAM management stand out as key benefits. Users are encouraged to update their devices to explore these new advantages fully. As Android continues to evolve, users can look forward to even more innovative features and improvements in the future.
                    `
                }
            ],
            blogImg: require('../assets/blog-post2.png'),
            nextimg: require('../assets/arrow-right.png')
        },
        {
            id: 3,
            title: 'Implementing DevOps: Best Practices for Success',
            description: `In today’s fast-paced tech world, companies need to build and roll out software solutions quicker than before. DevOps — which combines “development” and “operations” — has become the go-to method to achieve this. By closing the gap between development and IT operations, DevOps influences teamwork. It helps to streamline your workflow and make sure you’re always delivering top-notch software. But how can businesses put DevOps into practice? Here are some tips on the best ways to bring DevOps into your day-to-day operations.`,
            sections: [
                {
                    heading: 'What is DevOps?',
                    points: [
                        {
                            title: 'Definition',
                            description: 'DevOps has an influence on both culture and technology promoting teamwork, open dialogue, and automation between developers and IT ops staff.'
                        },
                        {
                            title: 'Objectives',
                            description: 'Make delivery faster, boost software quality, enhance system dependability, and build a culture where everyone shares responsibility.'
                        },
                        {
                            title: 'Mindset Shift',
                            description: 'To put DevOps into action, you need more than just tools and methods. It calls for a shift in mindset, workplace culture, and overall game plan.'
                        }
                    ]
                },
                {
                    heading: 'Why Use DevOps?',
                    points: [
                        {
                            title: '1. Faster time-to-market',
                            description: 'Smoother workflows cut down on holdups and speed up product releases.'
                        },
                        {
                            title: '2. Better quality',
                            description: 'Bringing things together and delivering non-stop makes sure testing happens all the time and catches problems.'
                        },
                        {
                            title: '3. Teams working better together',
                            description: 'People join forces, cutting down on isolated work and building a culture where everyone knows what’s going on.'
                        },
                        {
                            title: '4. Ability to grow and stay reliable',
                            description: 'Automation and monitoring make the backbone of the system work better and be more flexible.'
                        }
                    ]
                },
                {
                    heading: 'Best Practices for Implementing DevOps',
                    points: [
                        {
                            title: '1. Foster a DevOps Culture',
                            description: `DevOps isn’t just about technology; it’s a cultural shift. Begin by breaking down silos between teams and encouraging collaboration. Key steps include:
- Promote shared goals: Align teams around common objectives, such as faster delivery and improved customer satisfaction.
- Encourage open communication: Use tools like Slack or Microsoft Teams to facilitate real-time communication.
- Upskill teams: Train developers in operations and vice versa to build cross-functional expertise.`
                        },
                        {
                            title: '2. Start Small and Scale Gradually',
                            description: `Implementing DevOps across an entire organization at once can be overwhelming. Instead:
- Identify a pilot project: Choose a project with a manageable scope to test and refine your DevOps practices.
- Learn from feedback: Gather insights and iterate before scaling to larger projects.
- Set measurable goals: Define KPIs such as deployment frequency, lead time, and mean time to recovery (MTTR).`
                        },
                        {
                            title: '3. Automate Everything Possible',
                            description: `Automation is the backbone of DevOps. It reduces human error, increases efficiency, and accelerates processes.
- Continuous Integration/Continuous Delivery (CI/CD): Implement CI/CD pipelines to automate code building, testing, and deployment.
- Infrastructure as Code (IaC): Use tools like Terraform or AWS CloudFormation to manage infrastructure through code.
- Automated Testing: Incorporate unit, integration, and performance testing into your pipelines.`
                        },
                        {
                            title: '4. Use the Right Tools',
                            description: `Selecting the right tools is crucial for seamless DevOps adoption. Popular categories and examples include:
- Version Control: Git, GitHub, or GitLab.
- CI/CD: Jenkins, CircleCI, or Azure DevOps.
- Monitoring and Logging: Prometheus, Grafana, or Splunk.`
                        },
                        {
                            title: '5. Emphasize Continuous Monitoring',
                            description: `Monitoring systems in real time ensures that issues are detected and resolved promptly.
- Set up alerts: Use tools like Nagios or New Relic to notify teams of anomalies.
- Monitor user experience: Analyze metrics such as response times, error rates, and downtime.
- Log management: Centralize and analyze logs for insights into system performance.`
                        },
                        {
                            title: '6. Prioritize Security: DevSecOps',
                            description: `Security should be integrated into every stage of the DevOps lifecycle, not treated as an afterthought.
- Shift left security: Embed security practices early in the development process.
- Automate security checks: Use tools like Snyk or Aqua Security to scan for vulnerabilities.
- Implement role-based access: Limit permissions to reduce the risk of breaches.`
                        },
                        {
                            title: '7. Measure and Optimize',
                            description: `Continuous improvement is a cornerstone of DevOps. Regularly evaluate your processes and performance:
- Track KPIs: Monitor metrics such as deployment frequency, defect rates, and MTTR.
- Conduct retrospectives: After each sprint, assess what worked well and what needs improvement.
- Benchmark performance: Compare your outcomes against industry standards to identify gaps.`
                        }
                    ]
                },
                {
                    heading: 'Conclusion',
                    description: `Implementing DevOps is a transformative journey that can revolutionize how your organization delivers value to customers. By fostering a collaborative culture, leveraging automation, and focusing on continuous improvement, you can reap the benefits of faster delivery cycles, improved quality, and enhanced system resilience. Start small, iterate, and scale strategically — your DevOps success awaits!`
                }
            ],
            blogImg: require('../assets/devops-img.jpeg'),
            nextimg: require('../assets/arrow-right.png')
        }
    ];

    const openModal = (blog) => {
        setSelectedBlog(blog);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedBlog(null);
        setShowModal(false);
    };

    return (
        <>
            {screentype == 'Home' ? <div className="blogMain">
                <div className="container">
                    <div className="blog_banner text-center" style={{marginTop:'10px'}}>
                        <h1>
                            <span>
                                Latest Blogs
                                <img src={hightlightImg} alt="Highlight" />
                            </span>
                        </h1>
                    </div>
                    <div className="row">
                        {blogData.map((val) => (
                            <div key={val.id} className="col-lg-4 col-md-6 col-sm-12">
                                <div className="bloginner mt-4">
                                    <div className="blogImges">
                                        <img src={val.blogImg} style={{ width: '100%', height: '100%' }} alt="Blog" />
                                    </div>
                                    <div className="blogInnerData">
                                        <p className="mt-4">{val.title.slice(0, 70)}</p>
                                        <span className="clamped-text">{val.description.slice(0, 100)}...</span>
                                    </div>
                                    <button
                                        className="ms-auto d-flex btn btn-link"
                                        onClick={() => openModal(val)}
                                    >
                                        <img className="seeMoreIcon" src={val.nextimg} alt="Blog Icon" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {showModal && selectedBlog && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <button className="close-btn" onClick={closeModal}>
                                Close
                            </button>
                            <div className="modal-blog">
                                <img
                                    src={selectedBlog.blogImg}
                                    alt={selectedBlog.title}
                                    className="modal-img"
                                />
                                <h2>{selectedBlog.title}</h2>
                                <p>{selectedBlog.description}</p>
                                <div className="modal-sections">
                                    {selectedBlog.sections.map((section, index) => (
                                        <div key={index} className="modal-section">
                                            <h3>{section.heading}</h3>
                                            {section.points ? (
                                                <ul>
                                                    {section.points.map((point, i) => (
                                                        <li key={i}>
                                                            <strong>{point.title}</strong>
                                                            {point.description && (
                                                                <p>{point.description}</p>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>{section.description}</p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div> : <div className="blogMain mb-4">
                <div className="container">
                    <div className="blog_banner text-center"  style={{marginTop:'10px'}}>
                        <h1>
                            <span>
                                Latest Blogs
                                <img src={hightlightImg} alt="Highlight" />
                            </span>
                        </h1>
                    </div>
                    <div className="row">
                        {blogData.map((val) => (
                            <div key={val.id} className="col-lg-4 col-md-6 col-sm-12">
                                <div className="bloginner mt-4">
                                    <div className="blogImges">
                                        <img src={val.blogImg} style={{ width: '100%', height: '100%' }} alt="Blog" />
                                    </div>
                                    <div className="blogInnerData">
                                        <p className="mt-4">{val.title.slice(0, 70)}...</p>
                                        <span className="clamped-text">{val.description.slice(0, 100)}...</span>
                                    </div>
                                    <button
                                        className="ms-auto d-flex btn btn-link"
                                        onClick={() => openModal(val)}
                                    >
                                        <img className="seeMoreIcon" src={val.nextimg} alt="Blog Icon" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {showModal && selectedBlog && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <button className="close-btn" onClick={closeModal}>
                                Close
                            </button>
                            <div className="modal-blog">
                                <img
                                    src={selectedBlog.blogImg}
                                    alt={selectedBlog.title}
                                    className="modal-img"
                                />
                                <h2>{selectedBlog.title}</h2>
                                <p>{selectedBlog.description}</p>
                                <div className="modal-sections">
                                    {selectedBlog.sections.map((section, index) => (
                                        <div key={index} className="modal-section">
                                            <h3>{section.heading}</h3>
                                            {section.points ? (
                                                <ul>
                                                    {section.points.map((point, i) => (
                                                        <li key={i}>
                                                            <strong>{point.title}</strong>
                                                            {point.description && (
                                                                <p>{point.description}</p>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>{section.description}</p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>}
        </>
    );
};
export default Blogs;