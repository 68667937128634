import React from 'react';
import '../testimonials/Testimonial.css';
import backgroundImage from '../assets/Group-33906.png';
import hightlightImg from '../assets/highlightText.png';
import bannerImg from '../assets/proj-img1.png';
import bannerImg1 from '../assets/client1.jpeg';
import bannerImg2 from '../assets/client2.jpeg';
const Testimonial = ({ testimonial }) => {
    return (
        <div className='testimonialMain' style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className='container'>
                <div className='testimonial_banner text-center'>
                    <h1>
                        <span>
                            Testimonial
                            <img src={hightlightImg} alt="Description of the image" />
                        </span>
                    </h1>
                </div>
                <div id="carouselExampleFade" className="carousel slide carousel-fade mt-5" data-bs-ride="carousel" data-bs-interval="10000">
                    <div className="carousel-inner">
                        {testimonial?.testimonials?.map((testimonial, index) => (
                            <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={testimonial.id}>
                                <div className='clientDetail'>
                                    <div className='clientavtarFrame'>
                                        <img src={testimonial.imageUrl} className="d-block" alt={testimonial.name} />
                                    </div>
                                    <div className='clientFeedback'>
                                        <h4>{testimonial.name}</h4>
                                        <p>{testimonial.designation}</p>
                                        <span>{testimonial.description}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="carousel-indicators">
                        {testimonial?.testimonials?.map((_, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselExampleFade"
                                data-bs-slide-to={index}
                                className={index === 0 ? 'active' : ''}
                                aria-current={index === 0 ? 'true' : 'false'}
                                aria-label={`Slide ${index + 1}`}
                            ></button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Testimonial;