import axios from "axios";
import {
  BASEURL,
  HOME,
  SERVICES,
  TECHNOLOGIES,
  ABOUT,
  PRIVACYPOLICY,
  LIFEATGROWMORE,
  SUBMITCONTACT,
} from "../services/ApiUrl";
export const fetchHomeData = async (endpoint) => {
  try {
    const response = await axios.get(`${HOME}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchAboutData = async (endpoint) => {
  try {
    const response = await axios.get(`${ABOUT}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchServiceData = async (endpoint) => {
  try {
    const response = await axios.get(`${SERVICES}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchTechnologiesData = async (endpoint) => {
  try {
    const response = await axios.get(`${TECHNOLOGIES}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchPolicyData = async (endpoint) => {
  try {
    const response = await axios.get(`${PRIVACYPOLICY}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchGrowMoreLifeData = async (endpoint) => {
  try {
    const response = await axios.get(`${LIFEATGROWMORE}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const submitContactData = async (data) => {
  try {
    const response = await axios.post(`${SUBMITCONTACT}`, data);
    return response.data; // Handle the response data as needed
  } catch (error) {
    console.log("Error submitting data:", error);
    return error;
  }
};
